import { model } from '@telekomconsalting/dex-guru-model'
import { ReactComponent as Shark } from 'app-images/icons/emoji/shark.svg'
import { ReactComponent as Turtle } from 'app-images/icons/emoji/turtle.svg'
import { ReactComponent as Whale } from 'app-images/icons/emoji/whale.svg'
import { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { getBarValue } from '../../helpers/meterBarHelper'
import { State } from '../../reducers'
import MeterBar from '../MeterBar'

export type TraderType =
  | model.TraderCategoryName.casual
  | model.TraderCategoryName.medium
  | model.TraderCategoryName.heavy

export interface MarketScreenerItemProps {
  type: TraderType
  value: number
  isDisabled: boolean
}

export const MarketScreenerTypes: Record<TraderType, { icon: ReactElement; tooltip: string }> = {
  casual: {
    icon: <Turtle />,
    tooltip: 'Turtle a.k.a. Casual Trader with $10k-$100k of a trading volume in the last 30 days‌',
  },
  medium: {
    icon: <Shark />,
    tooltip: 'Shark a.k.a. Active Trader with $100k-$500k of a trading volume in the last 30 days‌',
  },
  heavy: {
    icon: <Whale />,
    tooltip: 'Whale a.k.a. Heavy Trader with $500k+ of a trading volume in the last 30 days',
  },
}

const MarketScreenerItem: FC<MarketScreenerItemProps> = ({ type, value, isDisabled }) => {
  const isMobile = useSelector((state: State) => state.isMobile)

  if (isDisabled) {
    return (
      <div className="market-screener__item" data-class={isMobile ? 'short' : undefined}>
        <div className="market-screener__icon">{MarketScreenerTypes[type].icon}</div>
        <MeterBar
          className="market-screener__lines"
          value={getBarValue(value)}
          isCompletelyFilled={isDisabled}
        />
      </div>
    )
  }

  return (
    <div
      className="market-screener__item"
      data-for="app-tooltip"
      data-tip={MarketScreenerTypes[type].tooltip}
      data-class={isMobile ? 'short' : undefined}>
      <div className="market-screener__icon">{MarketScreenerTypes[type].icon}</div>
      <MeterBar className="market-screener__lines" value={getBarValue(value)} suffix="%" />
    </div>
  )
}

export default MarketScreenerItem
