import angryCasual from 'app-images/icons/angry-casual.svg'
import exhaustedCasual from 'app-images/icons/exhausted-casual.svg'
import madCasual from 'app-images/icons/mad-casual.svg'
import spyWhale from 'app-images/icons/spy-whale.svg'
import { FC, useRef } from 'react'

const icons = [angryCasual, exhaustedCasual, madCasual, spyWhale]

export const MessageEmptyIcon: FC = () => {
  const showIcon = useRef<string>(icons[Math.floor(Math.random() * icons.length)])
  return (
    <div className="message__empty">
      <img className="message__mascot" src={showIcon.current} alt="empty" />
    </div>
  )
}

export default MessageEmptyIcon
