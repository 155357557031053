import { ReactComponent as Stop } from 'app-images/icons/stophand.svg'
import cn from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from '../../actions'
import { updateSettings } from '../../actions'
import { setTradeformType } from '../../actions/uiActions'
import { TradeformType, TradeType } from '../../model'
import { State } from '../../reducers'
import amplitudeService from '../../services/amplitudeService'
import { isChainReadOnly } from '../../services/chainService'
import gtmService from '../../services/gtmService'
import { getNetworkConfig } from '../../utils'
import CloseButton from '../buttons/CloseButton'
import ButtonsGroup from '../ButtonsGroup'
import Footer from '../Footer'
import FormDesktop from '../Form/FormDesktop'
import { FormVariants, formVariantsValues } from '../Form/helpers'
import Graph from '../Graph'
import SnackbarItem from './../../containers/SnackbarItem'

const MainPartDesktop: FC = () => {
  const reduxDispatch = useDispatch()
  const [formVariant, setFormVariant] = useState<TradeformType>(formVariantsValues[0].value)
  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const quoteToken = useSelector((state: State) => state.tokens.quoteToken)
  const { isChartFullScreen } = useSelector((state: State) => state.settings.settingsData)

  const { isSidebarOpen: sidebarOpen, tradeformType } = useSelector((state: State) => state.ui)

  const onTabClick = (value: string): void => {
    const activeTradeType: TradeType = value === 'Buy' ? 'Buy' : 'Sell'
    reduxDispatch(actions.setActiveTradeType(activeTradeType))

    if (currentToken && quoteToken) {
      gtmService.v3.viewProductDetails(currentToken, quoteToken)
      gtmService.v4.viewProductDetails(currentToken, quoteToken)
    }
  }

  const formInteractionHandler = (): void => {
    amplitudeService.interactedWithForm()
  }

  const networkConfig = currentToken && getNetworkConfig(currentToken.network)

  useEffect(() => {
    if (!networkConfig) {
      return
    }
    const doesSupportLimitOrders = !!networkConfig.one_inch_api?.url
    const doesSupportMarketOrders = !!networkConfig?.zerox_api?.url
    if (!doesSupportLimitOrders) {
      setFormVariant(FormVariants.market)
    }
    if (!doesSupportMarketOrders) {
      setFormVariant(FormVariants.limit)
    }
  }, [currentToken?.id, networkConfig])

  if (!currentToken || !networkConfig) {
    return null
  }

  const isMarketTypeLP = currentToken.marketType === 'lp'
  const isReadOnlyMarket = isChainReadOnly(networkConfig) || isMarketTypeLP
  const doesSupportLimitOrders = !!networkConfig.one_inch_api?.spender_address
  const doesSupportMarketOrders = !!networkConfig?.zerox_api?.url

  const handleFormVariantChange = (value: TradeformType): void => {
    setFormVariant(value)
    reduxDispatch(setTradeformType(value))
  }

  const handleFormClose = (): void => {
    reduxDispatch(
      updateSettings({
        isChartFullScreen: true,
        tvChartHeight: '',
      })
    )
  }

  const handleFormOpen = (): void => {
    reduxDispatch(
      updateSettings({
        isChartFullScreen: false,
        tvChartHeight: '',
      })
    )
  }

  const isShowForm = !(isReadOnlyMarket || isChartFullScreen)
  const isReadOnlyMessage = isMarketTypeLP
    ? 'We do not support trading LP tokens yet. Currently, they are read only.'
    : 'We do not support trading on this network just yet. Currently, this market is read-only.'

  const formButtons = formVariantsValues.map((x) => {
    if (
      (!doesSupportLimitOrders && x.value === FormVariants.limit) ||
      (!doesSupportMarketOrders && x.value === FormVariants.market)
    ) {
      return { ...x, disabled: true }
    } else {
      return x
    }
  })

  return (
    <div className={cn('main', 'main--desktop', { 'main--hide': sidebarOpen !== 'none' })}>
      <div className="main__chart">
        <Graph />
      </div>
      {isReadOnlyMarket && (
        <SnackbarItem message={isReadOnlyMessage} icon={<Stop />} className="read-only" />
      )}
      {isShowForm && (
        <div className="main__body">
          <div className="tradeform" onClick={formInteractionHandler}>
            <CloseButton size="sm" className="tradeform__hide" onClick={handleFormClose} />
            {process.env.REACT_APP_LIMIT_ORDERS === 'true' && (
              <div className="tradeform__header">
                <ButtonsGroup
                  buttons={formButtons}
                  value={tradeformType || formVariantsValues[0].value}
                  onChange={handleFormVariantChange}
                />
              </div>
            )}
            <div className="tradeform__body">
              <FormDesktop
                tradeType="Buy"
                formVariant={formVariant}
                onSelectTradeType={onTabClick}
              />
              <FormDesktop
                tradeType="Sell"
                formVariant={formVariant}
                onSelectTradeType={onTabClick}
              />
            </div>
          </div>
        </div>
      )}
      {!isShowForm && <button className="tradeform-show" onClick={handleFormOpen} />}
      <div className="main__footer">
        <Footer isMobile={false} isDashboard={true} />
      </div>
    </div>
  )
}

export default MainPartDesktop
