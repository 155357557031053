import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import React from 'react'

import { financialFormatLowValueHighPrecision } from '../helpers/priceAndAmountHelpers'

export interface MeterBarProps {
  className?: string
  value:
    | {
        positive?: number
        negative?: number
        neutral?: number
      }
    | number
  max?: number
  only?: 'positive' | 'negative'
  prefix?: string
  suffix?: string
  isCompletelyFilled?: boolean
}

export function MeterBar({
  className,
  value,
  max = 100,
  only,
  prefix,
  suffix,
  isCompletelyFilled,
}: MeterBarProps): React.ReactElement {
  const isValueNumber = typeof value === 'number'
  const objValue = !isValueNumber
    ? {
        negative: value.negative,
        neutral: value.neutral,
        positive: value.positive,
      }
    : {
        negative: value < 0 ? Math.abs(value) : 0,
        positive: value > 0 ? value : 0,
      }

  return (
    <div className={classNames('meter-bar', only ? `meter-bar--${only}` : '', className || '')}>
      {Object.entries(objValue).map((entry) => {
        const entryType = entry[0]
        const entryValue = entry[1]
        const isShown = (!only || only === entryType) && entryValue !== undefined
        const absValue = BigNumber.minimum(new BigNumber(entryValue || 0).abs(), max)

        if (!isShown) {
          return null
        }

        const width = isCompletelyFilled
          ? 100
          : absValue.multipliedBy(100).div(max).toNumber().toFixed(2)

        return (
          <span
            key={entryType}
            className={`meter-bar__meter meter-bar__meter--${entryType}`}
            title={`${prefix ? prefix : ''}${financialFormatLowValueHighPrecision(entryValue)}${
              suffix ? suffix : ''
            }`}
            style={{
              width: `${width}%`,
            }}></span>
        )
      })}
    </div>
  )
}

export default MeterBar
