import { ReactComponent as Angry } from 'app-images/icons/emoji/angry.svg'
import { ReactComponent as Stop } from 'app-images/icons/stophand.svg'
import { ReactComponent as Wallet } from 'app-images/icons/wallet.svg'
import { ReactComponent as Warning } from 'app-images/icons/warning.svg'
import classNames from 'classnames'
import { FC, ReactElement, ReactNode } from 'react'

import MessageEmptyIcon from './MessageEmptyIcon'

export const MessageIcons: Record<string, ReactElement> = {
  stop: <Stop />,
  error: <Angry />,
  warning: <Warning />,
  wallet: <Wallet />,
}

export enum MessageTypes {
  error = 'error',
  info = 'info',
  empty = 'empty',
}

export enum MessageSizes {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export const Message: FC<{
  text?: ReactNode
  type?: 'error' | 'info' | 'empty'
  size?: 'sm' | 'md' | 'lg'
  icon?: ReactElement
  className?: string
  isShowMascot?: boolean
}> = ({ text, icon, type = 'info', size = 'md', className, children, isShowMascot = true }) => {
  return (
    <div
      className={classNames(
        'message',
        className,
        { [`message--${size}`]: !!size },
        { [`message--${type}`]: !!type }
      )}>
      {icon && (
        <span className="message__icon">
          <span className="icon">{icon}</span>
        </span>
      )}
      <div className={classNames('message__text')}>
        <div className="message-text">
          <div className="text">{text || children}</div>
        </div>
      </div>
      {type === 'empty' && isShowMascot && <MessageEmptyIcon />}
    </div>
  )
}

export default Message
