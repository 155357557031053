import { ReactComponent as Free } from 'app-images/icons/emoji/beta-free.svg'
import classNames from 'classnames'
import lodashGet from 'lodash.get'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { MARKET_SCREENER_REFRESH_INTERVAL } from '../../config/settings'
import { isPaywallEnabled } from '../../constants'
import { Screener } from '../../model'
import { State } from '../../reducers'
import { getMarketScreener } from '../../services/marketScreenerService'
import { Heading } from '../Heading'
import LabelPro from '../LabelPro'
import MarketScreenerItem, { MarketScreenerTypes, TraderType } from './MarketScreenerItem'

const shownMarketScreener = Object.keys(MarketScreenerTypes) as TraderType[]

const MarketScreener: FC = () => {
  const [marketScreener, setMarketScreener] = useState<Screener>()
  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  const isMobile = useSelector((store: State) => store.isMobile)
  const isPaidUserPlan = useSelector((store: State) => store.isPaidUserPlan)

  const fetchMarketScreener = React.useCallback(async (): Promise<void> => {
    if (currentToken?.id) {
      const data = await getMarketScreener(currentToken?.id, currentToken?.network)
      setMarketScreener(data)
    }
  }, [currentToken?.id, currentToken?.network])

  useEffect(() => {
    fetchMarketScreener()
    const interval = setInterval(fetchMarketScreener, MARKET_SCREENER_REFRESH_INTERVAL)
    return (): void => clearInterval(interval)
  }, [fetchMarketScreener])

  if (!marketScreener) {
    return null
  }

  const isDisabled = isPaywallEnabled && !isPaidUserPlan

  return (
    <div className={classNames('market-screener', { mobile: isMobile })}>
      <div className="market-screener__header">
        <Heading size="xs">Market Screener</Heading>
        <span
          className="tooltip-anchor"
          data-for="app-tooltip"
          data-tip="Shows the difference between buying and selling volumes of different types of traders over the last 24h. See docs for more details."
          data-place={isMobile ? 'bottom' : 'top'}
        />
        {!isPaywallEnabled && (
          <Free
            className="market-screener__free"
            data-for="app-tooltip"
            data-tip="This feature is free while DexGuru is in Beta."
          />
        )}
        {isPaywallEnabled && !isPaidUserPlan && (
          <LabelPro
            label="horizontal"
            tooltip={`Upgrade to <span class="priority">DexGuru Pro</span> to unlock Market Screener`}
            styles={{ position: 'absolute', right: '-16px' }}
          />
        )}
      </div>
      <div className={classNames('market-screener__body', { disabled: isDisabled })}>
        {shownMarketScreener.map((category) => {
          const value = lodashGet(marketScreener, category, 0)
          return (
            <MarketScreenerItem
              type={category}
              value={value}
              key={category}
              isDisabled={isDisabled}
            />
          )
        })}
      </div>
    </div>
  )
}

export default MarketScreener
